<template>
  <div class="row">
    <!-- activeTab:{{activeTab}} -->
    <div class="col-12 col-md-2 col-xl-1 background-grey">
      <v-tabs v-model="activeTab" vertical class="mt-4">
        <v-tab class="justify-start" v-if="isTeam(auth)" @click="tabName = 'team'">
          <v-icon class="pr-2" size="20">fas fa-users</v-icon>Team
        </v-tab>
        <v-tab class="justify-start" @click="tabName = 'plan'"
          v-if="auth.authUser.account.selectedPlanId != 'po_internal'">
          <v-icon class="pr-3" size="22">far fa-credit-card</v-icon>Billing & Plan
        </v-tab>
        <v-tab class="justify-start" @click="tabName = 'profile'">
          <v-icon class="pr-3" size="22">fas fa-user</v-icon>Profile
        </v-tab>

        <v-tab class="justify-start" @click="
          showSubReport = false;
        tabName = 'reports';
        " v-if="isTeam(auth)">
          <v-icon class="pr-3" size="22">fas fa-chart-pie</v-icon>Reports
        </v-tab>

        <v-tab class="justify-start" @click="tabName = 'refresh'">
          <v-icon class="pr-3" size="22">fas fa-redo</v-icon>Refresh
        </v-tab>
      </v-tabs>
    </div>
    <div class="col-12 col-md-10 col-xl-11 pt-10">
      <v-tabs-items v-model="activeTab" class="tab-content">
        <v-tab-item v-if="isTeam(auth)">
          <Team></Team>
        </v-tab-item>
        <v-tab-item v-if="isCompanyAdmin(auth) && auth.authUser.account.selectedPlanId != 'po_internal'">
          <h4 class="mb-12">Enrollment Plan & Payments</h4>
          <Billing></Billing>
        </v-tab-item>
        <v-tab-item>
          <Profile></Profile>
        </v-tab-item>

        <v-tab-item v-if="isTeam(auth)">
          <!-- <Reports
            v-if="!showSubReport"
            v-bind:showSubReport.sync="showSubReport"
          ></Reports> -->
          <ReportProgress></ReportProgress>
        </v-tab-item>
        <v-tab-item v-if="isCompanyAdmin(auth)">
          <div v-if="tabName == 'refresh'">
            <Refresh></Refresh>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile";
import Team from "@/components/Team";
import Billing from "@/components/Billing";
import Refresh from "@/components/Refresh";
// import Reports from "@/components/Reports";
import ReportProgress from "@/components/reports/ReportProgress";
import { mapState } from "vuex";
import GET_USER from "../graphql/GetUser.gql";

export default {
  components: { Profile, Team, ReportProgress, Billing, Refresh },
  computed: mapState(["auth"]),
  data() {
    return {
      showSubReport: null,
      activeTab: 0,
      tabName: "plan",
      tabs: ["plan", "profile", "refresh"],
      tabsTeam: ["team", "plan", "profile", "reports", "refresh"],
    };
  },
  mounted() {
    const tabName = this.$route.query.tabName ? this.$route.query.tabName : "plan";
    this.tabName = tabName;
    if (this.isTeam(this.auth)) {
      this.activeTab = this.tabsTeam.indexOf(tabName);
    } else {
      this.activeTab = this.tabs.indexOf(tabName);
    }
    if (this.auth && this.auth.authUser) {
      this.getUsers();
    }
  },
  methods: {
    // auth.authUser.account.paymentPlanId
    isCompanyAdmin(auth) {
      try {
        return auth.authUser.isAdmin || auth.authUser.isCompanyAdmin;
      } catch (error) {
        return false;
      }
    },
    isTeam(auth) {
      // debugger;
      try {
        return (
          auth.authUser.isAdmin ||
          (auth.authUser.account && auth.authUser.account.paymentPlan && auth.authUser.account.paymentPlan.isTeam) ||
          auth.authUser.account.selectedPlanId == "po_internal"
        );
      } catch (error) {
        return false;
      }
    },
    async getUsers() {
      this.errors = [];
      let statuses = ["invited", "active"];
      if (this.showInactive) {
        statuses.push("in-active");
      }
      try {
        const userResult = await this.$apollo.mutate({
          mutation: GET_USER,
          variables: {
            _id: this.auth.authUser._id,
          },
        });
        try {
          let user = userResult.data.user[0];

          const authUser = Object.assign({}, this.auth.authUser, user);

          this.$store.dispatch("auth/signin", {
            authUser: authUser,
          });
        } catch (error) {
          console.log("update authUser error", error);
        }

      } catch (error) {
        this.errors.push(error.toString().replace("Error: GraphQL error: ", ""));
      }
    },
    // async getAccount(accountId) {
    //   const getAccountResult = await this.$apollo
    //     .query({
    //       query: GET_ACCOUNT,
    //       fetchPolicy: "no-cache",
    //       variables: {
    //         input: { _id: accountId },
    //       },
    //     })
    //     .catch((error) => {
    //       this.getAccountError = error.message
    //         ? error.message.replace("GraphQL error: ", "")
    //         : "error.";
    //     });
    //   const userAccount = getAccountResult.data.account[0];

    //   const authUser = Object.assign({}, this.auth.authUser, { account: userAccount });

    //   this.$store.dispatch("auth/signin", {
    //     authUser: signInResult.data.authUser,
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  width: 100%;
}

.tabs {
  // width: 200px;
}
</style>
